<template>
  <div class="spinner-container">
    <div class="spinner-content">
      <div class="spinner-text">
        LOADING...
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Spinner'
  }
</script>

<style lang="scss">
  .spinner-container {
    @include containerWidth(285px, 900px);
    @include containerPosition;
    .spinner-content {
      height: 300px;
      position: relative;
      @include backgroundAndPosition('../../public/logo.svg');
      background-size: 200px 200px;
      animation: spinner 3s linear infinite;
    }
    .spinner-text {
      position: absolute;
      top: 50%;
      @include horizontal-center;
      @include font;
      letter-spacing: 6px;
      animation: spinner-text 3s linear infinite;
    }
  }
  @keyframes spinner {
    0% {
      right: -25%;
    }
    25% {
      right: 0%;
    }
    50% {
      right: 25%;
    }
    75% {
      right: 0%;
    }
    100% {
      right: -25%;
    }
  }

  @keyframes spinner-text {
    0% {
      color: $yellow;
      font-size: 16px;
    }
    33% {
      color: $green;
      font-size: 13px;
    }
    66% {
      color: $blue;
      font-size: 16px;
    }
    100% {
      color: $gray-4;
      font-size: 13px;
    }
  }
</style>
