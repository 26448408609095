import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PageHeader from './components/PageHeader'

const app = createApp(App)
  .use(router)
  .use(store)
  .component('PageHeader', PageHeader)

app.config.unwrapInjectedRef = true
app.mount(document.body)
