<template>
  <div>
    <Alert v-if="$store.getters.getAlerts.length" />
    <router-view />
    <Disclaimer />
    <PageFooter />
  </div>
</template>
<script>
  import PageFooter from '@/components/PageFooter'
  import Alert from '@/components/Alert'
  import Disclaimer from '@/components/Disclaimer'
  import gtag from '../../sharedHelpers/gtag'
  import generateSessionId from '../../sharedHelpers/generateSessionId'

  export default {
    components: { PageFooter, Alert, Disclaimer },
    created() {
      let sessionId = window.localStorage.getItem('sessionId')
      if (!sessionId) {
        sessionId = generateSessionId()
        window.localStorage.setItem('sessionId', sessionId)
      }
      //NOTE: all gtag related property names use underscore by google convention
      //      ex. home_see_all
      gtag('js', new Date())
      let userInfo = JSON.parse(window.localStorage.getItem('profile'))
      this.$store.commit('updateProfile', {
        ...userInfo,
        sessionId
      })
    }
  }
</script>
