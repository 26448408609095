const manufacturerFilters = {
    Peterbilt: 'Peterbilt',
    Kenworth: 'Kenworth',
    International: 'International',
    Volvo: 'Volvo',
    Freightliner: 'Freightliner',
    'Western Star': 'Western Star',
    Mack: 'Mack',
  },
  keyOrder = {
    hasImage: '0',
    horsepower: '1',
    mUnitId: '2',
    manufacturer: '3',
    model: '4',
    page: '5',
    price: '6',
    state: '7',
    year: '8',
  }
export { manufacturerFilters, keyOrder }
