<template>
  <div>
    <div class="tab_container">
      <router-link
        to="/trucks-for-sale?page=1"
        active-class="active_tab"
        class="home_tab"
        >For Sale</router-link
      >
      <router-link
        to="/trucks-for-lease?page=1"
        active-class="active_tab"
        class="home_tab"
        >Lease/Purchase</router-link
      >
      <router-link
        to="/trucks-at-auction?page=1"
        active-class="active_tab"
        class="home_tab"
        >Auctions</router-link
      >
    </div>
  </div>
</template>
<style lang="scss">
  .tab_container {
    display: flex;
    padding: 6px 6px 0px 6px;
    a {
      &:first-child {
        border: 1px solid $green-1;
        flex: 5 5 auto;
        border-radius: 6px 0 0 6px;
      }
      &:nth-child(2) {
        border-top: 1px solid $green-1;
        border-bottom: 1px solid $green-1;
        flex: 6 6 auto;
      }
      &:last-child {
        border: 1px solid $green-1;
        flex: 5 5 auto;
        border-radius: 0 6px 6px 0;
      }
    }
  }
  // .dealer-name {
  //   padding: 1px 0;
  //   margin: 6px 6px 0 6px;
  //   @extend .tab_container;
  //   @extend .active_tab;
  //   @extend .home_tab;
  //   border-radius: 0.5rem;
  //   span {
  //     margin: 0 auto;
  //   }
  // }
  .active_tab {
    color: $white !important;
    background-color: $green-1 !important;
  }
  .home_tab {
    text-align: center;
    @include font(14px);
    line-height: 28px;
    color: $green-1;
    background-color: $white;
  }
</style>
