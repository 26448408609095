<template>
  <div class="explanation-container">
    <div class="explanation-content">
      <div class="first-line" :class="{ 'request-error': requestError }">
        <span>{{ requestError ? 'ERROR' : 'SORRY' }}</span>
      </div>
      <div class="second-line">
        {{ text }}
      </div>
      <!-- <div v-if="needButton" class="explanation-btn-div">
        <button
          @click="
            $router
              .push(`/${$route.path.split('/')[1]}?page=1`)
              .catch((err) => {})
          "
        >
          TAKE ME HOME
        </button>
      </div> -->
      <router-link
        v-if="needButton"
        class="explanation-btn"
        :to="`/${$route.path.split('/')[1]}?page=1`"
      >
        TAKE ME HOME
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WhenThingsGoWrong',
    props: {
      requestError: Boolean,
      text: String,
      needButton: Boolean
    }
  }
</script>

<style lang="scss">
  .explanation-container {
    @include containerWidth(285px, 960px);
    @include containerPosition;
    margin-bottom: 100px;
    padding: 12px;
    text-align: center;
    @include font(16px);
    .first-line {
      position: relative;
      margin: 12px auto;
      width: 130px;
      height: 50px;
      color: $gray-4;
      @include backgroundAndPosition($icon-gray-warning, left);
      text-shadow: 2px 2px $dark-1;
      background-size: 50px 50px;
      span {
        position: absolute;
        font-size: 24px;
        top: 30%;
        left: 40%;
      }
    }
    .request-error {
      color: $red;
      @include backgroundAndPosition($icon-red-warning, left);
      text-shadow: 2px 2px $red-mid;
    }

    .second-line {
      color: $gray-4;
      padding: 12px;
      line-height: 24px;
      text-shadow: 1px 1px $dark-1;
    }
  }
  .explanation-btn {
    text-align: center;
    padding: 9px;
    margin: 6px;
    width: 140px;
    @include font(11px);
    color: $white;
    background-color: $green-1;
    border-radius: 0.5rem;
    display: inline-block;
  }
</style>
