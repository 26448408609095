<template>
  <div class="alert-box">
    <div
      v-for="alert in $store.getters.getAlerts"
      :key="alert.id"
      :class="alert.msgType"
      @click="$store.commit('removeAlert', alert.id)"
      @animationend="$store.commit('removeAlert', alert.id)"
    >
      {{ alert.msg }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Alert',
  }
</script>

<style lang="scss">
  .alert-box {
    @include containerWidth(285px, 960px);
    @include fixedFeature;
    @include font(14px);
    width: 98%;
    opacity: 0.9;
    z-index: 44;
    text-align: center;
    & > div {
      position: relative;
      visibility: hidden;
      padding: 12px;
      margin: 0 auto 6px auto;
      @include alertAnimation;
    }
  }
  .success {
    color: $green-dark;
    @include borderAndBackgroundColor($green-light, 1px solid $green-mid);
  }
  .error {
    color: $red-dark;
    @include borderAndBackgroundColor($red-light, 1px solid $red-mid);
  }
  .warning {
    color: $yellow-dark;
    @include borderAndBackgroundColor($yellow-light, 1px solid $yellow-mid);
  }
  .info {
    color: $blue-dark;
    @include borderAndBackgroundColor($blue-light, 1px solid $blue-mid);
  }
</style>
