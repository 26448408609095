const siteName = 'daycabtrader',
  terms = {
    cookiePolicy: {
      text: 'Cookie Policy',
      url:
        'https://app.termly.io/document/privacy-policy/d80dcb70-8e2e-4f63-b809-c5e28d272607',
    },
    termOfUse: {
      text: 'Terms of Use',
      url:
        'https://app.termly.io/document/terms-of-use-for-website/b1b8367b-1ebe-4442-8427-0b0d19c67a6f',
    },
    privacyPolicy: {
      text: 'Privacy Policy',
      url:
        'https://app.termly.io/document/privacy-policy/d80dcb70-8e2e-4f63-b809-c5e28d272607',
    },
  },
  GA_TRACKING_ID = 'UA-111413542-7'

export { siteName, terms, GA_TRACKING_ID }
